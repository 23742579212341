
import {
  Component, Prop, Emit, Vue, InjectReactive,
} from 'nuxt-property-decorator'
import { ITeaserData } from '../../shared/general/interfaces/ITeaserData'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  name: 'TeaserResults',
  components: {
    BaseTeaser: () => import('../base/BaseTeaser.vue'),
    BaseButton: () => import('../base/BaseButton.vue'),
  },
})
export default class TeaserResults extends Vue {
  @InjectReactive({ from: 'gridSize' }) gridSize! : 'small' | 'wide'

  @Prop({ default: () => [] }) teasers! : ITeaserData[]

  @Prop({ default: 0 }) totalNumberOfResults! : number

  @Prop({ default: false }) showLoadingAnimation! : boolean

  private get loadMoreLabel () : string {
    return globalLabelAsString('load_more_label')
  }

  private get showLoadMoreButton () : boolean {
    return !!this.teasers.length && (this.totalNumberOfResults > this.teasers.length)
  }

  @Emit('load-more-results')
  private loadMoreResults () : void {}
}
